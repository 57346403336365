import { useState, useEffect } from "react";
import "./Register.scss";
import Form from "react-bootstrap/Form";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Example state and district data
const stateDistrictData = {
  "Andhra Pradesh": [
    "Visakhapatnam", "Vijayawada", "Guntur", "Kadapa", "Kakinada",
    "Nellore", "Tirupati", "Chittoor", "Anantapur", "Srikakulam",
    "Vizianagaram", "West Godavari", "East Godavari", "Krishna"
  ],
  "Arunachal Pradesh": [
    "Itanagar", "Tawang", "Pasighat", "Ziro", "Bomdila",
    "Changlang", "Aalo", "Namsai", "Yingkiong", "Nirjuli",
    "Daporijo", "Kangto", "Seppa"
  ],
  "Assam": [
    "Guwahati", "Dibrugarh", "Silchar", "Jorhat", "Tinsukia",
    "Nagaon", "Karimganj", "Haflong", "Dhemaji", "Lakhimpur",
    "Sonitpur", "Baksa", "Bongaigaon", "Barpeta"
  ],
  "Bihar": [
    "Patna", "Gaya", "Bhagalpur", "Muzaffarpur", "Purnia",
    "Sasaram", "Darbhanga", "Siwan", "Kishanganj", "Chapra",
    "Nawada", "Bhagalpur", "Buxar", "Jamui"
  ],
  "Chhattisgarh": [
    "Raipur", "Bilaspur", "Durg", "Korba", "Jagdalpur",
    "Raigarh", "Ambikapur", "Kanker", "Janjgir-Champa", "Bastar",
    "Dhamtari", "Kabirdham", "Balod"
  ],
  "Goa": [
    "Panaji", "Margao", "Vasco da Gama", "Ponda", "Mapusa",
    "Cortalim", "Quepem", "Sanguem", "Bicholim", "Canacona"
  ],
  "Gujarat": [
    "Ahmedabad", "Surat", "Vadodara", "Rajkot", "Gandhinagar",
    "Junagadh", "Bhavnagar", "Amreli", "Kutch", "Patan",
    "Mehsana", "Sabarkantha", "Dangs", "Girsomnath"
  ],
  "Haryana": [
    "Gurgaon", "Faridabad", "Panipat", "Ambala", "Hisar",
    "Karnal", "Yamunanagar", "Rohtak", "Jind", "Fatehabad",
    "Sirsa", "Mahendergarh", "Panchkula"
  ],
  "Himachal Pradesh": [
    "Shimla", "Manali", "Dharamshala", "Mandi", "Kullu",
    "Solan", "Bilaspur", "Hamirpur", "Chamba", "Una",
    "Sirmaur", "Kangra", "Lahaul and Spiti"
  ],
  "Jharkhand": [
    "Ranchi", "Jamshedpur", "Dhanbad", "Hazaribagh", "Dumka",
    "Giridih", "Deoghar", "Koderma", "Palamu", "Chatra",
    "Rural", "Seraikela", "Bokaro", "Pakur"
  ],
  "Karnataka": [
    "Bangalore", "Mysore", "Mangalore", "Hubli", "Dharwad",
    "Belgaum", "Bidar", "Gulbarga", "Shimoga", "Udupi",
    "Chikmagalur", "Hassan", "Kolar", "Tumkur", "Bagalkot",
    "Mandya", "Chitradurga", "Raichur", "Yadgir"
  ],
  "Kerala": [
    "Thiruvananthapuram", "Kochi", "Kozhikode", "Ernakulam", "Kottayam",
    "Palakkad", "Malappuram", "Wayanad", "Kannur", "Idukki",
    "Alappuzha", "Kasargod", "Pathanamthitta", "Kollam"
  ],
  "Madhya Pradesh": [
    "Bhopal", "Indore", "Gwalior", "Jabalpur", "Ujjain",
    "Sagar", "Satna", "Rewa", "Khandwa", "Shivpuri",
    "Dewas", "Khargone", "Mandsaur", "Ratlam"
  ],
  "Maharashtra": [
    "Mumbai", "Pune", "Nagpur", "Thane", "Nashik",
    "Aurangabad", "Jalgaon", "Kolhapur", "Solapur", "Satara",
    "Sindhudurg", "Ratnagiri", "Akola", "Amravati"
  ],
  "Manipur": [
    "Imphal", "Churachandpur", "Thoubal", "Bishnupur", "Senapati",
    "Tamenglong", "Ukhrul", "Chandel", "Jiribam", "Kangpokpi"
  ],
  "Meghalaya": [
    "Shillong", "Tura", "Jowai", "Bhoi", "Ri-Bhoi",
    "West Khasi Hills", "East Khasi Hills", "South West Khasi Hills",
    "South West Garo Hills", "West Garo Hills"
  ],
  "Mizoram": [
    "Aizawl", "Lunglei", "Saiha", "Champhai", "Mamit",
    "Kolasib", "Serchhip", "Lawngtlai", "Hnahthial"
  ],
  "Nagaland": [
    "Kohima", "Dimapur", "Mokokchung", "Mon", "Wokha",
    "Tuensang", "Phek", "Zunheboto", "Kiphire", "Longleng"
  ],
  "Odisha": [
    "Bhubaneswar", "Cuttack", "Rourkela", "Berhampur", "Sambalpur",
    "Balasore", "Kendrapara", "Khurda", "Jajpur", "Ganjam",
    "Bargarh", "Nabarangpur", "Koraput", "Boudh", "Kandhamal"
  ],
  "Punjab": [
    "Ludhiana", "Amritsar", "Jalandhar", "Patiala", "Mohali",
    "Bathinda", "Faridkot", "Ferozepur", "Moga", "Sri Muktsar Sahib",
    "Rupnagar", "Sangrur", "Barnala", "Kapurthala"
  ],
  "Rajasthan": [
    "Jaipur", "Udaipur", "Jodhpur", "Kota", "Bikaner",
    "Ajmer", "Sikar", "Churu", "Nagaur", "Dausa",
    "Pali", "Alwar", "Barmer", "Jhunjhunu"
  ],
  "Sikkim": [
    "Gangtok", "Namchi", "Pelling", "Mangan", "Rangpo"
  ],
  "Tamil Nadu": [
    "Chennai", "Coimbatore", "Madurai", "Salem", "Tiruchirappalli",
    "Tirunelveli", "Vellore", "Kanyakumari", "Dindigul", "Thanjavur",
    "Karur", "Nagapattinam", "Krishnagiri", "Erode", "Namakkal"
  ],
  "Telangana": [
    "Hyderabad", "Warangal", "Nizamabad", "Khammam", "Karimnagar",
    "Mahabubnagar", "Nalgonda", "Adilabad", "Medak", "Rangareddy"
  ],
  "Tripura": [
    "Agartala", "Udaipur", "Kailashahar", "Dharmanagar", "Sabroom",
    "Ambassa", "Khowai", "Teliamura", "Bisramganj", "Kamalgazi"
  ],
  "Uttar Pradesh": [
    "Lucknow", "Kanpur", "Varanasi", "Agra", "Meerut",
    "Bareilly", "Aligarh", "Moradabad", "Jhansi", "Gorakhpur",
    "Fatehpur", "Sultanpur", "Rampur", "Shahjahanpur", "Azamgarh"
  ],
  "Uttarakhand": [
    "Dehradun", "Haridwar", "Nainital", "Udham Singh Nagar", "Tehri Garhwal",
    "Pauri Garhwal", "Champawat", "Bageshwar", "Almora", "Pithoragarh"
  ],
  "West Bengal": [
    "Kolkata", "Darjeeling", "Siliguri", "Asansol", "Howrah",
    "Durgapur", "Kharagpur", "Jalpaiguri", "Medinipur", "Bankura",
    "Purulia", "Murarai", "Bardhaman", "Hooghly"
  ],
  "Andaman and Nicobar Islands": [
    "Port Blair", "Nicobar", "North Andaman", "South Andaman"
  ],
  "Chandigarh": [
    "Chandigarh"
  ],
  "Dadra and Nagar Haveli and Daman and Diu": [
    "Silvassa", "Daman", "Diu"
  ],
  "Lakshadweep": [
    "Kavaratti"
  ],
  "Delhi": [
    "New Delhi", "North Delhi", "South Delhi", "East Delhi", "West Delhi"
  ],
  "Puducherry": [
    "Puducherry", "Karaikal", "Yanam", "Mahe"
  ],
  "Ladakh": [
    "Leh", "Kargil"
  ],
  "Jammu and Kashmir": [
    "Srinagar", "Jammu", "Anantnag", "Pulwama", "Budgam",
    "Baramulla", "Kathua", "Rajouri", "Udhampur", "Doda",
    "Poonch", "Reasi", "Kishtwar"
  ]
};



const Register = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [referralId, setReferralId] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [districts, setDistricts] = useState([]);

  const [input, setInput] = useState({
    referral_id: "",
    name: "",
    email: "",
    mobile: "",
    gender: "",
    state: "",
    district: "",
    password: "",
    upi_type: "",
    confirm_password: "",
    upi_mobile:"",
    u_pincode:""
  });

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const referralId = params.get("referral_id");
    if (referralId) {
      setReferralId(referralId);
    }
    console.log(referralId);
  }, [location]);

  useEffect(() => {
    setInput((prevInput) => ({
      ...prevInput,
      referral_id: referralId,
    }));
  }, [referralId]);

  const notify = (message) => {
    toast(message);
  };

  const onHandleChange = (e) => {
    const { id, value, name } = e.target;
    setInput((prevFormData) => ({
      ...prevFormData,
      [name || id]: value,
    }));
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleStateChange = (e) => {
    const selectedState = e.target.value;
    setSelectedState(selectedState);
    setDistricts(stateDistrictData[selectedState] || []);
    setInput((prevInput) => ({
      ...prevInput,
      state: selectedState,
      district: "", // Reset district when state changes
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(input);
    if (
      !input.name ||
      !input.mobile ||
      !input.email ||
      !input.password ||
      !input.confirm_password ||
      !input.referral_id ||
      !input.gender ||
      !input.district ||
      !input.state ||
      !input.upi_type||
      !input.u_pincode

    ) {
      notify("Please enter all fields");
      console.log(input);
    } else if (input.password !== input.confirm_password) {
      notify("Passwords do not match");
    } else {
      try {
        const response = await axios.post(
          "https://lunarsenterprises.com:6001/e888/user/registration",
          input
        );
        console.log(response);

        const { result, message, referral_id } = response.data;
        if (response.data.result === true) {
          toast.success(message);
          localStorage.setItem("referral_id", response.data.referral_id);
          navigate("/post_login", {
            state: { input: input, referral_id: response.data.referral_id },
          });
        } else {
          toast.error(message);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <div className="container-fluid register">
      <ToastContainer />
      <form onSubmit={handleSubmit}>
        <h4>Create an Account</h4>

        <Form.Label htmlFor="name" className="mt-3">
          Name
        </Form.Label>
        <Form.Control
          type="text"
          id="name"
          name="name"
          placeholder="Enter your name"
          value={input.name}
          onChange={onHandleChange}
          required
          className="custom-placeholder"
        />

       
      

        <Form.Label htmlFor="gender" className="mt-3">
        Gender
      </Form.Label>
       
        <Form.Group>
          <Form.Check
            type="radio"
            id="gender"
            name="gender"
            value="Male"
            label="Male"
            onChange={onHandleChange}
            checked={input.gender === "Male"}
            inline
          />
          <Form.Check
            type="radio"
            id="Female"
            name="gender"
            value="Female"
            label="Female"
            onChange={onHandleChange}
            checked={input.gender === "Female"}
            inline
          />
          <Form.Check
            type="radio"
            id="gender"
            name="gender"
            value="Other"
            label="other"
            onChange={onHandleChange}
            checked={input.gender === "Other"}
            inline
          />
         
        </Form.Group>

        <Form.Label htmlFor="email" className="mt-3">
          Email
        </Form.Label>
        <Form.Control
          type="text"
          id="email"
          name="email"
          placeholder="Enter your email"
          value={input.email}
          onChange={onHandleChange}
          required
          className="custom-placeholder"
        />

        <Form.Label htmlFor="mobile" className="mt-3">
          Contact Number
        </Form.Label>
        <Form.Control
          type="phone"
          id="mobile"
          name="mobile"
          placeholder="Enter your mobile"
          value={input.mobile}
          onChange={onHandleChange}
          required
          className="custom-placeholder"
        />

        <Form.Label htmlFor="state" className="mt-3">
          State
        </Form.Label>
        <Form.Control
          as="select"
          id="state"
          name="state"
          value={input.state}
          onChange={handleStateChange}
          required
          className="custom-placeholder"
        >
          <option value="" disabled>
            Select State
          </option>
          {Object.keys(stateDistrictData).map((state) => (
            <option key={state} value={state}>
              {state}
            </option>
          ))}
        </Form.Control>

        <Form.Label htmlFor="district" className="mt-3">
          District
        </Form.Label>
        <Form.Control
          as="select"
          id="district"
          name="district"
          value={input.district}
          onChange={onHandleChange}
          required
          className="custom-placeholder"
          disabled={!input.state} // Disable district dropdown if no state is selected
        >
          <option value="" disabled>
            Select District
          </option>
          {districts.map((district) => (
            <option key={district} value={district}>
              {district}
            </option>
          ))}
        </Form.Control>

        <Form.Label htmlFor="u_pincode" className="mt-3">
        Pincode
      </Form.Label>
      <Form.Control
        type="number"
        id="u_pincode"
        name="u_pincode"
        placeholder="Enter your Pincode"
        value={input.u_pincode}
        onChange={onHandleChange}
        required
        className="custom-placeholder"
      />

        <Form.Label htmlFor="upi_type" className="mt-3">
          Select UPI
        </Form.Label>
        <Form.Group>
          <Form.Check
            type="radio"
            id="gpay"
            name="upi_type"
            value="GPay"
            label="GPay"
            onChange={onHandleChange}
            checked={input.upi_type === "GPay"}
            inline
          />
          <Form.Check
            type="radio"
            id="paytm"
            name="upi_type"
            value="Paytm"
            label="Paytm"
            onChange={onHandleChange}
            checked={input.upi_type === "Paytm"}
            inline
          />
          <Form.Check
            type="radio"
            id="phonepe"
            name="upi_type"
            value="PhonePe"
            label="PhonePe"
            onChange={onHandleChange}
            checked={input.upi_type === "PhonePe"}
            inline
          />
          <Form.Check
            type="radio"
            id="bhim"
            name="upi_type"
            value="BHIM"
            label="BHIM"
            onChange={onHandleChange}
            checked={input.upi_type === "BHIM"}
            inline
          />
        </Form.Group>

        <Form.Label htmlFor="upi_mobile" className="mt-3">
        UPI Id / Number
      </Form.Label>
      <Form.Control
        type="phone"
        id="upi_mobile"
        name="upi_mobile"
        placeholder="Enter your UPI Id"
        value={input.upi_mobile}
        onChange={onHandleChange}
        required
        className="custom-placeholder"
      />

        <Form.Label htmlFor="password" className="mt-3">
          Password
        </Form.Label>
        <div className="password">
          <Form.Control
            type={passwordVisible ? "text" : "password"}
            id="password"
            name="password"
            value={input.password}
            placeholder="Enter your password"
            onChange={onHandleChange}
            required
            className="custom-placeholder"
          />
          <div onClick={togglePasswordVisibility}>
            {passwordVisible ? (
              <FaEyeSlash className="closedeye" />
            ) : (
              <FaEye className="openeye" />
            )}
          </div>
        </div>

        <Form.Label htmlFor="confirm_password" className="mt-3">
          Confirm Password
        </Form.Label>
        <Form.Control
          type="password"
          id="confirm_password"
          name="confirm_password"
          placeholder="Confirm your password"
          value={input.confirm_password}
          onChange={onHandleChange}
          required
          className="custom-placeholder"
        />

        <Form.Label htmlFor="referral" className="mt-3">
          Connect Id
        </Form.Label>
        <Form.Control
          type="text"
          id="referral"
          name="referral_id"
          value={input.referral_id}
          placeholder="Referral Id"
          onChange={onHandleChange}
    
          className="custom-placeholder"
        />

        <button type="submit" className="registerBtn my-3">
          Register Now
        </button>

        <div className="text-center mb-3">
          <NavLink to="/">Go back to the home page</NavLink>
        </div>
      </form>
    </div>
  );
};

export default Register;
